export default {
  1: {
    name: 'anzan',
  },
  2: {
    name: 'multiply',
  },
  3: {
    name: 'pillars',
  },
};
