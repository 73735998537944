

export default {
  buttonClick: '../static/sounds/buttonClick.mp3',
  wrong: '../static/sounds/wrong.mp3',
  win: '../static/sounds/win.mp3',
  pip: '../static/sounds/pip.mp3',
  countdown: '../static/sounds/countdown.mp3',
  chip: '../static/sounds/chip.mp3',
};
