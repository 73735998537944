
export default {
  'ru': {
    'login.username': 'Имя пользователя',
    'login.password': 'Пароль',
    'login.error': 'Неверный пароль или логин!',
    'login.authorization': 'Авторизация',
    'login': 'Войти',
    'login.nonActivatedUserPage.warning': 'Уупс! Ваш пакет был истечен! Обратитесь вашему администратору школы, чтобы обновить пакет.',
    'login.logout': 'Войти',
    'login.adminUpdateMyAccess': 'Админ обновил мои доступы',
    'common.LinkMobileApp': 'Доступно мобильная версия',
    'common.LinkMobileApp.hide': 'Скрыть',
    'common.NotFoundPermissionDenied': 'Страница не найдено или у вас нет прав для просмотра данной страницы.',
    'homePage.myProfile': 'Мой профиль',
    'homePage.openGame': 'Открыть игры',
    'homePage.ratingStudent': 'Рейтинг учеников',
    'homePage.searchStudents': 'Поиск студентов',
    'homePage.changePassword': 'Изменить пароль',
    'homePage.logout': 'Выйти',
    'navBar.appBar.Home': 'Главная',
    'navBar.sideBar.ratingStudent': 'Рейтинг учеников',
    'navBar.sideBar.profile': 'Профиль',
    'navBar.sideBar.changePassword': 'Изменить пароль',
    'navBar.sideBar.logout': 'Выйти',
    'navbarGame.operation': 'Операции:',
    'navbarGame.number': 'Цифры:',
    'navbarGame.speed': 'Скорость:',
    'navbarGame.action': 'Действии:',
    'profile.card.createOrUpdate.schoolName': 'Название школы',
    'profile.card.createOrUpdate.schoolAdminName': 'Имя директора',
    'profile.card.createOrUpdate.schoolAdminSurName': 'Фамилия директора',
    'profile.card.createOrUpdate.schoolAdminUserName': 'Имя пользователя(логин)',
    'profile.card.createOrUpdate.schoolAdminPhone': 'Телефон',
    'profile.card.createOrUpdate.activateSchool': 'Активировать школу',
    'profile.card.createOrUpdate.Update': 'Редактировать школу',
    'profile.card.createOrUpdate.create': 'Создать',
    'profile.card.createOrUpdate.cancel': 'ОТМЕНИТЬ',
    'profile.card.mySchools.mySchools': 'Мои школы',
    'profile.card.mySchools.searchByName': 'Поиск по названию',
    'profile.card.mySchools.create': 'Создать',
    'profile.card.mySchools.name': 'Название',
    'profile.card.mySchools.dateOfCreation': 'Дата создания',
    'profile.card.mySchools.remainingPackage': 'Остаток пакета',
    'profile.card.mySchools.activate': 'Активен',
    'profile.card.mySchools.moreDetails': 'Подробнее',
    'profile.card.mySchools.update': 'Редактировать',
    'profile.card.mySchools.changePasswordAdmin': 'Изменить пароль админа',
    'profile.card.printResultsDialog.printResult': 'Напечатать результаты',
    'profile.card.printResultsDialog.text': 'Выберите начальную и конечную дату для скачивание результатов в этом отрезке времени.',
    'profile.card.printResultsDialog.startDate': 'Начальная дата',
    'profile.card.printResultsDialog.finishDate': 'Конечная дата',
    'profile.card.printResultsDialog.back': 'Назад',
    'profile.card.printResultsDialog.downloadResult': 'Скачать результаты',
    'profile.card.printResultsDialog.printing': 'Печать',
    'profile.card.printResultsDialog.dataIsReadyToPrint': 'Данные готовы к распечатке!',
    'profile.card.studentProfile.access': 'Доступы',
    'profile.card.studentProfile.close': 'Закрыт',
    'profile.card.studentProfile.showAccesses': 'Показать доступы',
    'profile.card.superAdminProfile.superAdmin': 'Супер админ',
    'profile.card.superAdminProfile.empty': 'Пусто',
    'profile.card.superAdminProfile.student': 'студентов',
    'profile.common.changeChildPassword.changePassword': 'Изменить пароль пользователя',
    'profile.common.changeChildPassword.newPassword': 'Новый пароль',
    'profile.common.changeChildPassword.passwordConfirmation': 'Подтверждение пароля',
    'profile.common.changeChildPassword.change': 'Изменить',
    'profile.common.gameResultTable.dayTime': 'День / Время',
    'profile.common.gameResultTable.game': 'Игра',
    'profile.common.gameResultTable.correctlyAnswered': 'Правильно ответил',
    'profile.common.gameResultTable.totalAmount': 'Общее количество',
    'profile.common.gameResultTable.pointsTotal': 'Сумма баллов',
    'profile.common.gameResultTable.moreAboutTheGame': 'Побробнее об игре',
    'profile.common.gameResultTable.numberOfActions': 'Количество действий',
    'profile.common.gameResultTable.numberOfDigitsP': 'Количество цифр (+)',
    'profile.common.gameResultTable.numberOfDigitsM': 'Количество цифр (-)',
    'profile.common.gameResultTable.usedNumbersP': 'Использованные числа (+)',
    'profile.common.gameResultTable.usedNumbersM': 'Использованные числа (-)',
    'profile.common.gameResultTable.speed': 'Скорость',
    'profile.common.gameResultTable.replied': 'Ответил',
    'profile.common.gameResultTable.correctAnswer': 'Правильный ответ',
    //
    'empty': 'Пустой',
    'ok': 'OK',
    'cancel': 'Отменить',
    'myProfile': 'Мой профиль',
    'home': 'Главная',
    'changePassword': 'Изменить пароль',
    'logout': 'Выйти',
    'firstname': 'Имя',
    'lastname': 'Фамилия',
    'username': 'Имя пользователя',
    'fullName': 'ФИО',
    'phone': 'Телефон',
    'email': 'Эл.Почта',
    'activeUntil': 'Активен до',
    'editProfile': 'Редактировать профиль',
    'searchStudent': 'Поиск студентов',
    'all': 'Все',
    'filterByTeacher': 'Фильтр по учителям',
    'withoutGroup': 'Без группы',
    'filterByGroup': 'Фильтр по группам',
    'filterByDateGame': 'Фильтр по дате игры',
    'search': 'Поиск',
    'loginText': 'логин:',
    'password': 'пароль:',
    'toComplete': 'Завершить',
    'settings': 'Настройки',
    'next': 'Далее',
    'start': 'Старт',
    'toAnswer': 'Ответить',
    'correctAnswer': 'Правильный ответ',
    'yourReplace': 'Ваш ответ',
    'game': 'Игры',
    'close': 'Закрыть',
    'min': 'мин',
    'sec': 'сек',
    'viewResult': 'Посмотреть результат',
    'menu': 'Меню',
    'apply': 'Применить',
    'playGame': 'ИГРАТЬ',
    'back': 'Назад',
    'yes': 'Да',
    'teachers': 'Учителя',
    // game
    'numberOfAction': 'Количество действии:',
    'chooseAnExpression': 'Выбирайте выражение:',
    'chooseAnAction': 'Выберите действие:',
    'theGameIsUnderDevelopment!': 'Игра на стадии разработки!',
    //
    'profile.common.studentActionsMenu.openProfile': 'Открыть профиль', // 3
    'profile.common.studentActionsMenu.updateProfile': 'Редактировать профиль',
    'profile.common.studentActionsMenu.renewPackage': 'Продлить пакет',
    'profile.common.studentActionsMenu.changePassword': 'Изменить пароль',
    'profile.common.studentActionsMenu.delete': 'Удалить',
    'profile.common.studentTable.lFPName': 'ФИО',
    'profile.common.studentTable.school': 'Школа',
    'profile.common.studentTable.teacher': 'Учитель',
    'profile.common.studentTable.activeUntil': 'Активен до',
    'profile.common.studentTable.points': 'Баллы',
    'profile.common.studentTable.rankingPlace': 'Место в рейтинге',
    'profile.studentProfile.studentProfilePage.lastName': 'Фамилия: ',
    'profile.studentProfile.studentProfilePage.firstName': 'Имя: ',
    'profile.studentProfile.studentProfilePage.login': 'Логин: ',
    'profile.studentProfile.studentProfilePage.teacher': 'Учитель: ',
    'profile.studentProfile.studentProfilePage.school': 'Школа: ',
    'profile.studentProfile.studentProfilePage.score': 'Балл: ',
    'profile.studentProfile.studentProfilePage.studentResult': 'Результаты студента',
    'profile.studentProfile.studentProfilePage.archives': 'Архивы',
    'profile.studentProfile.studentProfilePage.print': 'Печатать',
    'profile.studentProfile.studentResultArchives.errorMessage': 'Студент не найден, или у вас нет прав для просмотра',
    'profile.studentProfile.studentResultArchives.resultArchives': 'Архивы результатов',
    'profile.studentProfile.studentResultArchives.point': 'Печатать',
    'profile.studentProfile.studentResultDateRanges.span': 'Результаты по играм пусты',
    'profile.superAdmin.packageProposalsPage.packageRequests': 'Заявки на пакеты',
    'profile.superAdmin.searchStudentPage.filterStudent': 'Поиск студентов',
    'profile.superAdmin.searchStudentPage.result': 'Результаты',
    'profile.superAdmin.searchStudentPage.filterByName': 'Поиск по Имени / Фамилиии',
    'profile.superAdmin.searchStudentPage.filterBySchool': 'Фильтр по школам',
    'profile.superAdmin.searchStudentPage.filterByTeacher': 'Фильтр по учителям',
    'profile.superAdmin.searchStudentPage.all': 'Все',
    'profile.superAdmin.searchStudentPage.withoutGroup': 'Без группы',
    'profile.superAdmin.searchStudentPage.filterByGroups': 'Фильтр по группам',
    'profile.superAdmin.searchStudentPage.empty': 'Пусто',
    'profile.superAdmin.searchStudentPage.filterByGameDate': 'Фильтр по дате игры',
    'profile.superAdmin.searchStudentPage.search': 'Поиск',
    'profile.teacher.chartResult.point': 'Баллы',
    'profile.teacher.main.students': 'Студенты',
    'profile.teacher.main.createStudent': 'Создать ученика',
    'profile.teacher.main.filterByName': 'Поиск по Имени / Фамилии',
    'profile.teacher.main.all': 'Все',
    'profile.teacher.main.withoutGroup': 'Без группы',
    'profile.teacher.main.filterByGroups': 'Фильтр по группам',
    'profile.teacher.main.group': 'Группы',
    'profile.teacher.main.createGroup': 'Создать группу',
    'profile.teacher.main.name': 'Название',
    'profile.teacher.main.numberOfStudent': 'Количество студентов',
    'profile.teacher.main.update': 'Редактировать',
    'profile.teacher.main.delete': 'Удалить',
    'profile.teacher.studentPage.resultsStudents': 'Результаты студента',
    'profile.teacher.studentPage.archive': 'Архивы',
    'profile.teacher.studentPage.point': 'Печатать',
    'profile.teacher.studentPrint.resultStudent': 'Результаты ученика:',
    'profile.teacher.studentPrint.game': 'Игра',
    'profile.teacher.studentPrint.date': 'Дата',
    'profile.teacher.studentPrint.right': 'Правильно',
    'profile.teacher.studentPrint.all': 'Все',
    'profile.teacher.studentPrint.overallScore': 'Общий балл',
    'profile.teacher.studentPrint.numberOfActions': 'Количество действий',
    'profile.teacher.studentPrint.numberOfNumberP': 'Количество цифр (+)',
    'profile.teacher.studentPrint.numberOfNumberM': 'Количество цифр (-)',
    'profile.teacher.studentPrint.speed': 'Скорость',
    'profile.teacher.studentPrint.replied': 'Ответил',
    'profile.teacher.studentPrint.correctAnswer': 'Правильный ответ',
    'profile.teacher.studentPrint.usedNumberP': 'Использованные числа (+)',
    'profile.teacher.studentPrint.usedNumberM': 'Использованные числа (-)',
    'profile.changePasswordPage.changePasswordAccess': 'Изменения пароля прошло успешно!',
    'profile.changePasswordPage.changePassword': 'Сменить пароль',
    'profile.changePasswordPage.oldPassword': 'Старый пароль',
    'profile.changePasswordPage.newPassword': 'Новый пароль',
    'profile.changePasswordPage.confirmPassword': 'Подтвердите Пароль',
    'profile.changePasswordPage.change': 'Изменить',
    'profile.navBar.packageRequests': 'Заявки на пакеты',
    'profile.navBar.searchStudent': 'Поиск студентов',
    'profile.navBar.game': 'Игры',
    'profile.navBar.ratingStudent': 'Рейтинг учеников',
    'profile.schoolInfoPage.allInfo': 'Общая информация о школе',
    'profile.schoolInfoPage.edit': 'Редактировать',
    'profile.schoolInfoPage.name': 'Название: ',
    'profile.schoolInfoPage.fullNameDirector': 'ФИО директора:',
    'profile.schoolInfoPage.phone': 'Телефон:',
    'profile.schoolInfoPage.teacherAndStudent': 'Учители / Ученики школы',
    'profile.schoolInfoPage.numberOfTeacher': 'Количество учителей:',
    'profile.schoolInfoPage.numberOfStudent': 'Количство учеников: ',
    'profile.schoolInfoPage.access': 'Доступы',
    'profile.schoolInfoPage.username': 'Имя пользователя: ',
    'profile.schoolInfoPage.password': 'Пароль:',
    'profile.schoolInfoPage.currentPocket': 'Текущий покет',
    'profile.schoolInfoPage.packagePrice': 'Цена пакета:',
    'profile.schoolInfoPage.numberOfAccount': 'Количество аккаунтов:',
    'profile.schoolInfoPage.packageDuration': 'Длительность пакета:',
    'profile.schoolInfoPage.months': 'месяцев',
    'profile.schoolInfoPage.remainingPackages': 'Остаток пакета: ',
    'profile.schoolInfoPage.enterAPasswordToConfirm': 'Введите пароль для потверждения действия.',
    'profile.schoolInfoPage.toBeAdded': 'Будет добавлено:',
    'profile.schoolInfoPage.asAResult': 'В результате: ',
    'profile.schoolInfoPage.addPackage': 'Добавить пакет',
    'profile.schoolInfoPage.chooseANewTariffPlan': 'Выберите новый тарифный план.',
    'profile.schoolInfoPage.changeTariff': 'Смена тарифа доступно только после оканчания текущего пакета.',
    'profile.schoolInfoPage.numberOfAccounts': 'Кол. аккаунтов:',
    'profile.schoolInfoPage.totalPrice': 'Общая цена:',
    'profile.schoolInfoPage.som': 'сом',
    'profile.schoolInfoPage.update': 'Обновить',
    'profile.schoolInfoPage.enterPassword': 'Введите пароль',
    'profile.schoolInfoPage.confirm': 'Подтвердить',
    'schoolAdmin.form.groupForm.nameGroup': 'Название группы',
    'schoolAdmin.form.groupForm.save': 'Сохранить',
    'schoolAdmin.form.personForm.teacher': 'Учитель',
    'schoolAdmin.form.personForm.errorUser': 'Такой пользователь уже существует! ',
    'schoolAdmin.createGroupPage.createGroup': 'Создать группу для учителя ',
    'schoolAdmin.createStudent': 'Создать студента',
    'schoolAdmin.createTeacher': 'Создать учителя',
    'schoolAdmin.editGroupPage': 'Редактирование группы',
    'schoolAdmin.renewingAPackage': 'Возобновление пакета',
    'schoolAdmin.changeTariff': 'Сменить тариф',
    'schoolAdmin.applicationHistory': 'История заявок',
    'schoolAdmin.teacherPackage': 'Пакет учителя',
    'schoolAdmin.teacherPackage2': 'успешно обновлен!',
    'schoolAdmin.personTable.openProfile': 'Открыть профиль',
    'schoolAdmin.personTable.editProfile': 'Редактировать профиль',
    'schoolAdmin.personTable.updatePackage': 'Обновить пакет',
    'schoolAdmin.profileCard.school': 'школа',
    'schoolAdmin.profileCard.student': 'студентов',
    'schoolAdmin.profileCard.teacher': 'учитителей',
    'schoolAdmin.profileCard.restOfThePackage': 'остаток покета',
    'schoolAdmin.profileCard.getThePackage': 'Получить пакет',
    'schoolAdmin.schoolSearchStudent.result': 'Результаты',
    'schoolAdmin.studentTable.students': 'Студенты',
    'schoolAdmin.studentTable.create': 'Создать',
    'schoolAdmin.updateSchoolAdmin': 'Редактировать скул админ',
    'schoolAdmin.updateStudentProfile': 'Редактировать студента',
    'schoolAdmin.updateTeacher': 'Редактировать учителя',
    'game.resultComponent': 'посмотреть результаты',
    'game.yourResponse': 'Ваш ответ:',
    'game.correctAnswer': 'Правильный ответ:',
    'game.numberOfQuestions:': 'Количество вопросов:',
    'game.rightAnswers': 'Правильные ответы:',
    'game.incorrectAnswers': 'Не правильные ответы:',
    'game.common.gameOperation.ltr': 'Справа налево',
    'game.common.gameOperationCenterAC.firstMultiplier': '1й множитель',
    'game.common.gameOperationCenterAC.module': 'Модуль',
    'game.common.gameOperationCenterAC.twoMultiplier': '2й множитель',
    'game.common.selectMultiplier.numberOfDigit': 'Количество цифр',
    'game.common.selectTime.time': 'Скорость:',
    'game.generatorGame.downloadData': 'Загрузить данные со стилями',
    'game.generatorGame.taskTable': 'Таблица задач №',
    'game.generatorGame.downloadExcel': 'Загрузить в Excel',
    'game.generatorGame.point': 'Распечатать',
    'game.generatorGame.settingModal.moduleP': '+ Модуль',
    'game.generatorGame.settingModal.moduleM': '- Модуль',
    'game.generatorGame.settingModal.numberOfDigitP': '+ Количество цифр:',
    'game.generatorGame.settingModal.numberOfDigitM': '+ Количество цифр:',
    'game.generatorGame.settingModal.numberOfTable': 'Количество таблицы',
    'game.generatorGame.settingModal.numberOfColumns': 'Количество калонок',
    'game.modal.alertDialogZeroing.yourAssignmentsWillBeLost': 'Ваши задания пропадут!',
    'game.modal.alertDialogZeroing.areYouSure': 'Вы уверены? Обнуляться все баллы и вы начнете с самого начала',
    'game.modal.alertDialogZeroing.chooseNumbers': 'Выбирайте цифр',
    'game.modal.settingsGameAfterburnerModal.voiceGuidance': 'Голосовое сопровождения',
    'game.modal.rating.mainRatings.globalRating': 'Глобальный рейтинг',
    'game.modal.rating.mainRatings.schoolRating': 'Школьный рейтинг',
    'game.modal.studentsListTable.aPlace': 'Место',
    'game.modal.studentsListTable.participant': 'Участник',
    'game.modal.studentsListTable.school': 'Школа',
    'game.modal.studentsListTable.points': 'Баллы',
    //
    'profile.superAdmin.packageProposalsPage.allSchools': 'По всем школам',
    'profile.superAdmin.packageProposalsPage.pending': 'В ожидании',
    'profile.superAdmin.packageProposalsPage.canceled': 'Отмененные',
    'profile.superAdmin.packageProposalsPage.rejected': 'Отказанные',
    'profile.superAdmin.packageProposalsPage.adopted': 'Принятые',
    'profile.superAdmin.packageProposalsPage.school': 'Школа',
    'profile.superAdmin.packageProposalsPage.qty.accounts': 'Кол. аккаунтов',
    'profile.superAdmin.packageProposalsPage.duration': 'Длительность',
    'profile.superAdmin.packageProposalsPage.price': 'Цена',
    'profile.superAdmin.packageProposalsPage.created': 'Создано',
    'profile.superAdmin.packageProposalsPage.accepted': 'Принято',
    'profile.superAdmin.packageProposalsPage.deny': 'Отказать',
    'profile.superAdmin.packageProposalsPage.toAccept': 'Принять',
    //
    'game.generatorGame.multiplication.module': 'Модуль',
    //
    'schoolAdmin.makePackageProposalPage.remainingOfTheCurrentPackage': 'Остаток нынешнего пакета:',
    'schoolAdmin.makePackageProposalPage.sendARequest': 'Отправить заявку',
    'schoolAdmin.makePackageProposalPage.departureDate': 'Дата отправки',
    'schoolAdmin.makePackageProposalPage.status': 'Статус',
    // name games
    'anzan': 'Анзан',
    'multiply': 'Умножайка',
    'pillars': 'Столбики',
    'questGenerator': 'Генератор заданий',
    'abacus': 'Абакус',

    'multiplication': 'Умножение',
    'division': 'Деление',
    'square': 'Квадрат',
    'cube': 'Куб',
    'squareRoot': 'Квадратный корень',
    'cubicRoot': 'Кубический корень',
    //
    'CREATE_TEACHER': 'Создать',
    'CREATE_STUDENT': 'Создать',
    'UPDATE_TEACHER': 'Редактировать',
    'UPDATE_STUDENT': 'Редактировать',
    //
    'pending': 'В ожидании',
    'canceled': 'Отменен',
    'rejected': 'Отклонен',
    'accepted': 'Принят',
    //
    'superAdminId': 'Гендиректор',
    'adminId': 'Директор филиала',
    'teacherId': 'Учитель',
    'studentId': 'Студент',
  },

  'en': {
    'login.username': 'Username',
    'login.password': 'Password',
    'login.error': 'Invalid password or login!',
    'login.authorization': 'Authorization',
    'login': 'Login',
    'login.nonActivatedUserPage.warning': 'Oops! Your package has expired! Contact your school administrator to update the package.',
    'login.logout': 'Logout',
    'login.adminUpdateMyAccess': 'Admin updated my access',
    'common.LinkMobileApp': 'Mobile version available',
    'common.LinkMobileApp.hide': 'Hide',
    'common.NotFoundPermissionDenied': 'The page was not found or you do not have permission to view this page.',
    'homePage.myProfile': 'My profile',
    'homePage.openGame': 'Open Games',
    'homePage.ratingStudent': 'Student rating',
    'homePage.searchStudents': 'Search for students',
    'homePage.changePassword': 'Change Password',
    'homePage.logout': 'Logout',
    'navBar.appBar.Home': 'home',
    'navBar.sideBar.ratingStudent': 'Student rating',
    'navBar.sideBar.profile': 'Profile',
    'navBar.sideBar.changePassword': 'Change Password',
    'navBar.sideBar.logout': 'Logout',
    'navbarGame.operation': 'Operations:',
    'navbarGame.number': 'Number:',
    'navbarGame.speed': 'Speed:',
    'navbarGame.action': 'Action:',
    'profile.card.createOrUpdate.schoolName': 'School name',
    'profile.card.createOrUpdate.schoolAdminName': 'Director\'s name',
    'profile.card.createOrUpdate.schoolAdminSurName': 'Director\'s surname',
    'profile.card.createOrUpdate.schoolAdminUserName': 'Username (login)',
    'profile.card.createOrUpdate.schoolAdminPhone': 'Phone',
    'profile.card.createOrUpdate.activateSchool': 'Activate school',
    'profile.card.createOrUpdate.Update': 'Edit school',
    'profile.card.createOrUpdate.create': 'Create',
    'profile.card.createOrUpdate.cancel': 'Cancel',
    'profile.card.mySchools.mySchools': 'My schools',
    'profile.card.mySchools.searchByName': 'Search by name',
    'profile.card.mySchools.create': 'Create',
    'profile.card.mySchools.name': 'Name',
    'profile.card.mySchools.dateOfCreation': 'Date of creation',
    'profile.card.mySchools.remainingPackage': 'Remaining package',
    'profile.card.mySchools.activate': 'Active',
    'profile.card.mySchools.moreDetails': 'More details',
    'profile.card.mySchools.update': 'Edit',
    'profile.card.mySchools.changePasswordAdmin': 'Change admin password',
    'profile.card.printResultsDialog.printResult': 'Print results',
    'profile.card.printResultsDialog.text': 'Select the start and end date for downloading the results in this time interval.',
    'profile.card.printResultsDialog.startDate': 'Start date',
    'profile.card.printResultsDialog.finishDate': 'Final date',
    'profile.card.printResultsDialog.back': 'Back to',
    'profile.card.printResultsDialog.downloadResult': 'Download Results',
    'profile.card.printResultsDialog.printing': 'Printing',
    'profile.card.printResultsDialog.dataIsReadyToPrint': 'Data is ready to print!',
    'profile.card.studentProfile.access': 'Access',
    'profile.card.studentProfile.close': 'Closed',
    'profile.card.studentProfile.showAccesses': 'Show accesses',
    'profile.card.superAdminProfile.superAdmin': 'Super admin',
    'profile.card.superAdminProfile.empty': 'Empty',
    'profile.card.superAdminProfile.student': 'Students',
    'profile.common.changeChildPassword.changePassword': 'Change user password',
    'profile.common.changeChildPassword.newPassword': 'New password',
    'profile.common.changeChildPassword.passwordConfirmation': 'Password confirmation',
    'profile.common.changeChildPassword.change': 'Change',
    'profile.common.gameResultTable.dayTime': 'Day / Time',
    'profile.common.gameResultTable.game': 'Game',
    'profile.common.gameResultTable.correctlyAnswered': 'Correctly answered',
    'profile.common.gameResultTable.totalAmount': 'Total amount',
    'profile.common.gameResultTable.pointsTotal': 'Points total',
    'profile.common.gameResultTable.moreAboutTheGame': 'More about the game',
    'profile.common.gameResultTable.numberOfActions': 'Number of actions',
    'profile.common.gameResultTable.numberOfDigitsP': 'Number of digits (+)',
    'profile.common.gameResultTable.numberOfDigitsM': 'Number of digits (-)',
    'profile.common.gameResultTable.usedNumbersP': 'Used numbers (+)',
    'profile.common.gameResultTable.usedNumbersM': 'Used numbers (-)',
    'profile.common.gameResultTable.speed': 'Speed',
    'profile.common.gameResultTable.replied': 'Replied',
    'profile.common.gameResultTable.correctAnswer': 'Correct answer',
    //
    'empty': 'Empty',
    'ok': 'OK',
    'cancel': 'Cancel',
    'myProfile': 'My profile',
    'home': 'Home',
    'changePassword': 'Change password',
    'logout': 'Logout',
    'firstname': 'First name',
    'lastname': 'Last name',
    'username': 'Username',
    'fullName': 'Full name',
    'phone': 'Phone',
    'email': 'Email',
    'activeUntil': 'Active until',
    'editProfile': 'Edit profile',
    'searchStudent': 'Search students',
    'all': 'Everything',
    'filterByTeacher': 'Filter by teachers',
    'withoutGroup': 'Without group',
    'filterByGroup': 'Filter by group',
    'filterByDateGame': 'Filter by game date',
    'search': 'Search',
    'loginText': 'Login:',
    'password': 'Password:',
    'toComplete': 'To complete',
    'settings': 'Settings',
    'next': 'Next',
    'start': 'Start',
    'toAnswer': 'To answer',
    'correctAnswer': 'Correct answer',
    'yourReplace': 'Your response',
    'game': 'Games',
    'close': 'Close',
    'min': 'min',
    'sec': 'sec',
    'viewResult': 'View result',
    'menu': 'Menu',
    'apply': 'Apply',
    'playGame': 'PLAY',
    'back': 'Back to',
    'yes': 'Yes',
    'teachers': 'Teachers',
    // game
    'numberOfAction': 'Number of actions:',
    'chooseAnExpression': 'Choose an expression:',
    'chooseAnAction': 'Choose an action:',
    'theGameIsUnderDevelopment!': 'The game is under development!',
    //
    'profile.common.studentActionsMenu.openProfile': 'Open profile', // 3
    'profile.common.studentActionsMenu.updateProfile': 'Edit profile',
    'profile.common.studentActionsMenu.renewPackage': 'Renew package',
    'profile.common.studentActionsMenu.changePassword': 'Change Password',
    'profile.common.studentActionsMenu.delete': 'Delete',
    'profile.common.studentTable.lFPName': 'Full name',
    'profile.common.studentTable.school': 'School',
    'profile.common.studentTable.teacher': 'Teacher',
    'profile.common.studentTable.activeUntil': 'Active until',
    'profile.common.studentTable.points': 'Points',
    'profile.common.studentTable.rankingPlace': 'Ranking place',
    'profile.studentProfile.studentProfilePage.lastName': 'Last name: ',
    'profile.studentProfile.studentProfilePage.firstName': 'First name: ',
    'profile.studentProfile.studentProfilePage.login': 'Login: ',
    'profile.studentProfile.studentProfilePage.teacher': 'Teacher: ',
    'profile.studentProfile.studentProfilePage.school': 'School: ',
    'profile.studentProfile.studentProfilePage.score': 'Score: ',
    'profile.studentProfile.studentProfilePage.studentResult': 'Result student',
    'profile.studentProfile.studentProfilePage.archives': 'Archives',
    'profile.studentProfile.studentProfilePage.print': 'Print',
    'profile.studentProfile.studentResultArchives.errorMessage': 'Student not found or you are not authorized to view',
    'profile.studentProfile.studentResultArchives.resultArchives': 'Results Archives',
    'profile.studentProfile.studentResultArchives.point': 'Print',
    'profile.studentProfile.studentResultDateRanges.span': 'Game results are empty',
    'profile.superAdmin.packageProposalsPage.packageRequests': 'Package requests',
    'profile.superAdmin.searchStudentPage.filterStudent': 'Search students',
    'profile.superAdmin.searchStudentPage.result': 'Results',
    'profile.superAdmin.searchStudentPage.filterByName': 'Search by Name / Surname',
    'profile.superAdmin.searchStudentPage.filterBySchool': 'Filter by schools',
    'profile.superAdmin.searchStudentPage.filterByTeacher': 'Filter by teachers',
    'profile.superAdmin.searchStudentPage.all': 'Everything',
    'profile.superAdmin.searchStudentPage.withoutGroup': 'Without group',
    'profile.superAdmin.searchStudentPage.filterByGroups': 'Filter by groups',
    'profile.superAdmin.searchStudentPage.empty': 'Empty',
    'profile.superAdmin.searchStudentPage.filterByGameDate': 'Filter by game date',
    'profile.superAdmin.searchStudentPage.search': 'Search',
    'profile.teacher.chartResult.point': 'Points',
    'profile.teacher.main.students': 'Students',
    'profile.teacher.main.createStudent': 'Create a student',
    'profile.teacher.main.filterByName': 'Search by Name / Surname',
    'profile.teacher.main.all': 'Everything',
    'profile.teacher.main.withoutGroup': 'Without group',
    'profile.teacher.main.filterByGroups': 'Filter by groups',
    'profile.teacher.main.group': 'Groups',
    'profile.teacher.main.createGroup': 'Create group',
    'profile.teacher.main.name': 'Name',
    'profile.teacher.main.numberOfStudent': 'Number of students',
    'profile.teacher.main.update': 'Edit',
    'profile.teacher.main.delete': 'Deleted',
    'profile.teacher.studentPage.resultsStudents': 'Student results',
    'profile.teacher.studentPage.archive': 'Archives',
    'profile.teacher.studentPage.point': 'Print',
    'profile.teacher.studentPrint.resultStudent': 'Student results:',
    'profile.teacher.studentPrint.game': 'Game',
    'profile.teacher.studentPrint.date': 'Date',
    'profile.teacher.studentPrint.right': 'Right',
    'profile.teacher.studentPrint.all': 'Everything',
    'profile.teacher.studentPrint.overallScore': 'Overall score',
    'profile.teacher.studentPrint.numberOfActions': 'Number of actions',
    'profile.teacher.studentPrint.numberOfNumberP': 'Number of digits (+)',
    'profile.teacher.studentPrint.numberOfNumberM': 'Number of digits (-)',
    'profile.teacher.studentPrint.speed': 'Speed',
    'profile.teacher.studentPrint.replied': 'Replied',
    'profile.teacher.studentPrint.correctAnswer': 'Correct answer',
    'profile.teacher.studentPrint.usedNumberP': 'Used numbers (+)',
    'profile.teacher.studentPrint.usedNumberM': 'Used numbers (-)',
    'profile.changePasswordPage.changePasswordAccess': 'Password change was successful!',
    'profile.changePasswordPage.changePassword': 'Change password',
    'profile.changePasswordPage.oldPassword': 'Old password',
    'profile.changePasswordPage.newPassword': 'New password',
    'profile.changePasswordPage.confirmPassword': 'Confirm the password',
    'profile.changePasswordPage.change': 'Edit',
    'profile.navBar.packageRequests': 'Package requests',
    'profile.navBar.searchStudent': 'Search Students',
    'profile.navBar.game': 'Games',
    'profile.navBar.ratingStudent': 'Rating student',
    'profile.schoolInfoPage.allInfo': 'General information about the school',
    'profile.schoolInfoPage.edit': 'Edit',
    'profile.schoolInfoPage.name': 'Name: ',
    'profile.schoolInfoPage.fullNameDirector': 'Full name of the director:',
    'profile.schoolInfoPage.phone': 'Phone:',
    'profile.schoolInfoPage.teacherAndStudent': 'Teachers / Students of the school',
    'profile.schoolInfoPage.numberOfTeacher': 'Number of teachers:',
    'profile.schoolInfoPage.numberOfStudent': 'Number of students: ',
    'profile.schoolInfoPage.access': 'Access',
    'profile.schoolInfoPage.username': 'Username: ',
    'profile.schoolInfoPage.password': 'password:',
    'profile.schoolInfoPage.currentPocket': 'Current pocket',
    'profile.schoolInfoPage.packagePrice': 'Package price:',
    'profile.schoolInfoPage.numberOfAccount': 'Number of accounts:',
    'profile.schoolInfoPage.packageDuration': 'Package duration:',
    'profile.schoolInfoPage.months': 'months',
    'profile.schoolInfoPage.remainingPackages': 'Remaining package: ',
    'profile.schoolInfoPage.enterAPasswordToConfirm': 'Enter a password to confirm the action.',
    'profile.schoolInfoPage.toBeAdded': 'Will be added:',
    'profile.schoolInfoPage.asAResult': 'As a result: ',
    'profile.schoolInfoPage.addPackage': 'Add package',
    'profile.schoolInfoPage.chooseANewTariffPlan': 'Choose a new tariff plan.',
    'profile.schoolInfoPage.changeTariff': 'Tariff change is available only after the end of the current package.',
    'profile.schoolInfoPage.numberOfAccounts': 'Qty. accounts:',
    'profile.schoolInfoPage.totalPrice': 'Total price:',
    'profile.schoolInfoPage.som': 'som',
    'profile.schoolInfoPage.update': 'Refresh',
    'profile.schoolInfoPage.enterPassword': 'Enter password',
    'profile.schoolInfoPage.confirm': 'Confirm',
    'schoolAdmin.form.groupForm.nameGroup': 'Group name',
    'schoolAdmin.form.groupForm.save': 'Save',
    'schoolAdmin.form.personForm.teacher': 'Teacher',
    'schoolAdmin.form.personForm.errorUser': 'This user already exists! ',
    'schoolAdmin.createGroupPage.createGroup': 'Create a group for a teacher ',
    'schoolAdmin.createStudent': 'Create student',
    'schoolAdmin.createTeacher': 'Create teacher',
    'schoolAdmin.editGroupPage': 'Edit group',
    'schoolAdmin.renewingAPackage': 'Renewing a package',
    'schoolAdmin.changeTariff': 'Change tariff',
    'schoolAdmin.applicationHistory': 'Application history',
    'schoolAdmin.teacherPackage': 'Teacher package',
    'schoolAdmin.teacherPackage2': 'Successfully updated!',
    'schoolAdmin.personTable.openProfile': 'Open profile',
    'schoolAdmin.personTable.editProfile': 'Edit Profile',
    'schoolAdmin.personTable.updatePackage': 'Update package',
    'schoolAdmin.profileCard.school': 'School',
    'schoolAdmin.profileCard.student': 'Student',
    'schoolAdmin.profileCard.teacher': 'Teachers',
    'schoolAdmin.profileCard.restOfThePackage': 'Rest of the package',
    'schoolAdmin.profileCard.getThePackage': 'Get the package',
    'schoolAdmin.schoolSearchStudent.result': 'Results',
    'schoolAdmin.studentTable.students': 'Students',
    'schoolAdmin.studentTable.create': 'Create',
    'schoolAdmin.updateSchoolAdmin': 'Edit school admin',
    'schoolAdmin.updateStudentProfile': 'Edit student',
    'schoolAdmin.updateTeacher': 'Edit teacher',
    'game.resultComponent': 'See results',
    'game.yourResponse': 'Your response:',
    'game.correctAnswer': 'Correct answer:',
    'game.numberOfQuestions:': 'Number of questions:',
    'game.rightAnswers': 'Right answers:',
    'game.incorrectAnswers': 'Wrong answers:',
    'game.common.gameOperation.ltr': 'From right to left',
    'game.common.gameOperationCenterAC.firstMultiplier': '1st multiplier digit',
    'game.common.gameOperationCenterAC.module': '-Module',
    'game.common.gameOperationCenterAC.twoMultiplier': '2st multiplier digit',
    'game.common.selectMultiplier.numberOfDigit': 'Number of digits',
    'game.common.selectTime.time': 'Speed:',
    'game.generatorGame.downloadData': 'Load data with styles',
    'game.generatorGame.taskTable': 'Task table №',
    'game.generatorGame.downloadExcel': 'Upload to Excel',
    'game.generatorGame.point': 'Print',
    'game.generatorGame.settingModal.moduleP': '+ Numbers',
    'game.generatorGame.settingModal.moduleM': '- Numbers',
    'game.generatorGame.settingModal.numberOfDigitP': '+ Number of digits:',
    'game.generatorGame.settingModal.numberOfDigitM': '- Number of digits:',
    'game.generatorGame.settingModal.numberOfTable': 'Table number',
    'game.generatorGame.settingModal.numberOfColumns': 'Number of columns',
    'game.modal.alertDialogZeroing.yourAssignmentsWillBeLost': 'Your tasks will be lost!',
    'game.modal.alertDialogZeroing.areYouSure': 'Are you sure? Reset all points and you will start from the very beginning',
    'game.modal.alertDialogZeroing.chooseNumbers': 'Choose numbers',
    'game.modal.settingsGameAfterburnerModal.voiceGuidance': 'Voice guidance',
    'game.modal.rating.mainRatings.globalRating': 'Global ranking',
    'game.modal.rating.mainRatings.schoolRating': 'School rating',
    'game.modal.studentsListTable.aPlace': 'Place',
    'game.modal.studentsListTable.participant': 'Participant',
    'game.modal.studentsListTable.school': 'School',
    'game.modal.studentsListTable.points': 'Points',
    //
    'profile.superAdmin.packageProposalsPage.allSchools': 'All schools',
    'profile.superAdmin.packageProposalsPage.pending': 'Pending',
    'profile.superAdmin.packageProposalsPage.canceled': 'Canceled',
    'profile.superAdmin.packageProposalsPage.rejected': 'Rejected',
    'profile.superAdmin.packageProposalsPage.adopted': 'Adopted',
    'profile.superAdmin.packageProposalsPage.school': 'School',
    'profile.superAdmin.packageProposalsPage.qty.accounts': 'Qty. accounts',
    'profile.superAdmin.packageProposalsPage.duration': 'Duration',
    'profile.superAdmin.packageProposalsPage.price': 'Price',
    'profile.superAdmin.packageProposalsPage.created': 'Created',
    'profile.superAdmin.packageProposalsPage.accepted': 'Accepted',
    'profile.superAdmin.packageProposalsPage.deny': 'Deny',
    'profile.superAdmin.packageProposalsPage.toAccept': 'To accept',
    //
    'game.generatorGame.multiplication.module': 'Numbers',
    //
    'schoolAdmin.makePackageProposalPage.remainingOfTheCurrentPackage': 'Remaining of the current package:',
    'schoolAdmin.makePackageProposalPage.sendARequest': 'Send a request',
    'schoolAdmin.makePackageProposalPage.departureDate': 'Departure date',
    'schoolAdmin.makePackageProposalPage.status': 'Status',
    // name games
    'anzan': 'Anzan',
    'multiply': 'Multiplication',
    'pillars': 'Columns',
    'questGenerator': 'Quest generator',
    'abacus': 'Abacus',

    'multiplication': 'Multiplication',
    'division': 'Division',
    'square': 'Square',
    'cube': 'Cube',
    'squareRoot': 'Square root',
    'cubicRoot': 'Cubic root',
    //
    'CREATE_TEACHER': 'Create',
    'CREATE_STUDENT': 'Create',
    'UPDATE_TEACHER': 'Edit',
    'UPDATE_STUDENT': 'Edit',
    //
    'pending': 'Pending',
    'canceled': 'Canceled',
    'rejected': 'Rejected',
    'accepted': 'Accepted',
    //
    'superAdminId': 'CEO',
    'adminId': 'Branch Manager',
    'teacherId': 'Teacher',
    'studentId': 'Student',
  },
};
